import { RouteLocationNormalized } from '#vue-router'
import { authApi } from '~/src/services/requests/auth'

export const useMiddlewareHelpers = () => {
  const inviteHasAccount = async (route: RouteLocationNormalized) => {
    const reference = route.params.reference as string
    const res = await authApi.$_confirmInvite(reference)

    return res?.data?.user_exist
  }

  const getCollabRedirectRoute = async (route: RouteLocationNormalized) => {
    // use nuxt generated name for collab page
    if (route.name !== 'collab-confirm-reference') return

    const isExistingUser = await inviteHasAccount(route)

    return {
      path: isExistingUser ? '/auth/login' : '/auth/signup',
      query: {
        redirect: route.path,
      },
    }
  }

  return {
    getCollabRedirectRoute,
  }
}
